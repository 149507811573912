body {
    margin: 0;
    padding: 0;
    font-family: 'Vollkorn SC', serif;
}

.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
}

.intro-text {
    animation: fade 3s;
    font-size: 24px;
    text-align: center;
}

@keyframes fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
}